'use client';

import useStoreLink from '@/hook/use-appstore.hook';
import Typography from '../typography/typography.component';
import { useAppSelector, useTranslation } from '@/hook';
import { openApp } from '@/utils';
import { appScreensMap } from '@/constants';
import { useAnalytics } from '@/integrations';
import useAuth from '@/hook/use-auth.hook';

const GetAppHeader = () => {
    const storeLink = useStoreLink();
    const { t } = useTranslation();
    const { selectedCar } = useAppSelector(state => state.checkout);

    const { trackEvent } = useAnalytics();
    const { userId, phone, email } = useAuth();

    return (
        <div
            className={'md:hidden'}
            onClick={() => {
                const carID = selectedCar?.id || ''; // This will be used in case of extracting id from thank-you route (booking car) to go to financing screen
                const pathname = window.location.pathname.replace(
                    /^\/(en|ar)\//,
                    ''
                );
                const pathSegments = pathname.split('/');
                let matchedScreen: string = null;
                for (const screen of appScreensMap) {
                    const routeParts = screen.websiteRoute.split('/');
                    if (pathSegments.length >= routeParts.length) {
                        let id = null;
                        let isMatch = true;
                        for (let i = 0; i < routeParts.length; i++) {
                            if (routeParts[i].startsWith(':')) {
                                id = pathSegments[i];
                            } else if (routeParts[i] !== pathSegments[i]) {
                                isMatch = false;
                                break;
                            }
                        }
                        if (isMatch) {
                            if (screen.websiteRoute === 'thank-you') {
                                matchedScreen = screen.targetScreen(carID); // Use carID from Redux
                            } else if (
                                screen.websiteRoute ===
                                'mybookings/:bookingId/payments'
                            ) {
                                matchedScreen = screen.targetScreen(id); // Use bookingId from dynamic path
                            } else if (
                                screen.websiteRoute ===
                                'account/mybookings/:bookingId/payments'
                            ) {
                                matchedScreen = screen.targetScreen(id); // Use bookingId from dynamic path
                            } else {
                                matchedScreen = screen.targetScreen(id); // Use dynamic ID for other routes
                            }
                            break;
                        }
                    }
                }
                if (matchedScreen) {
                    trackEvent('Click On Download App', {
                        userId,
                        sourceButton: 'Get App',
                        phone,
                        email,
                    });
                    openApp({
                        storeLink,
                        targetScreen: matchedScreen,
                    });
                } else {
                    openApp({
                        storeLink,
                        targetScreen: 'HomeScreen',
                    });
                }
            }}
        >
            <div className="bg-primary-50 px-3 py-1.5 rounded-lg">
                <Typography
                    variant={'captionSemiBold'}
                    className="!text-primary-600"
                >
                    {t('getApp')}
                </Typography>
            </div>
        </div>
    );
};
export default GetAppHeader;
