'use client';
import styles from './styles.module.scss';
import { useAppSelector, useTranslation, useWindowDimensions } from '@/hook';
import Link from '@/components/WrappedLink';
import Typography from '@/components/shared/typography/typography.component';
import {
    FacebookLogo,
    FooterBackground,
    InstagramLogo,
    LinkedinLogo,
    PhoneIcon,
    websiteLogoArWhite,
    GetOnAppleStore,
    GetOnAndroidStore,
    GetOnHuaweiStore,
} from '../../../../public/Images';
import { usePathname } from 'next/navigation';
import Image from 'next/image';
import { useContext, useEffect, useState } from 'react';
import {
    APP_STORE_LINK,
    HUAWEI_PLAY_LINK,
    AVAILABLE_LANGUAGES,
    DEFAULT_LANGUAGE,
    GOOGLE_PLAY_LINK,
    appScreensMap,
} from '@/constants';
import myImageLoader from '@/image.loader';
import { useAnalytics } from '@/integrations';
import { possibleBuyUseCarsSources } from '@/types/filter.types';
import { shouldHideFooter } from '@/utils/footer.utils';
import { ACTION_CLICK_BUY_CAR } from '@/constants/actions';
import { globalConfigContext } from '@/wrappers/globalConfig.weapper';
import { openApp } from '@/utils';
import useAuth from '@/hook/use-auth.hook';
import useUserAgent from '@/hook/checkMobileType';

const SOCIAL_MEDIA_LINKS = [
    {
        id: 0,
        url: 'https://www.linkedin.com/company/sylndr/',
        logo: LinkedinLogo.src,
    },
    {
        id: 1,
        url: 'https://www.facebook.com/SylndrEgypt/',
        logo: FacebookLogo.src,
    },
    {
        id: 2,
        url: 'https://instagram.com/sylndr_eg?igshid=MmIxOGMzMTU=',
        logo: InstagramLogo.src,
    },
];

const NAVIGATION_LINKS = [
    {
        category: 'Product',
        links: [
            { label: 'Overview Footer', path: '' },
            { label: 'Sell Car', path: 'sell-car' },
            { label: 'Buy Car', path: 'buy-used-cars' },
            { label: 'Finance', path: 'car-finance' },
            { label: 'FAQs', path: 'how-it-works' },
        ],
    },
    {
        category: 'Company',
        links: [
            { label: 'About Us Header', path: 'about-us' },
            { label: 'Careers', path: 'https://jobs.lever.co/sylndr' },
            { label: 'Blog', path: 'https://sylndr.com/ar/blog/home/' },
        ],
    },
    {
        category: 'Social',
        links: [
            {
                label: 'Facebook',
                path: 'https://www.facebook.com/SylndrEgypt/',
            },
            {
                label: 'LinkedIn',
                path: 'https://www.linkedin.com/company/sylndr/',
            },
            {
                label: 'Instagram',
                path: 'https://instagram.com/sylndr_eg?igshid=MmIxOGMzMTU=',
            },
        ],
    },
    {
        category: 'Legal',
        links: [
            { label: 'Terms and Conditions', path: 'terms-and-conditions' },
            { label: 'Privacy Policy', path: 'privacy-policy' },
        ],
    },
];

export default function Footer() {
    const { t, i18n } = useTranslation();
    const { userId, phone, email } = useAuth();
    const lang = i18n.language;
    const path = usePathname();
    const { trackEvent } = useAnalytics();
    const [hideFooter, setHideFooter] = useState(true);
    const { IS_DESKTOP: desktopView } = useWindowDimensions();
    const { selectedCar } = useAppSelector(state => state.checkout);
    const logoByLanguage: Record<string, string> = {
        [AVAILABLE_LANGUAGES.en]:
            'https://website-images.sylndr.com/sylndrLogo.bf711e0b2230cc0313d8-min.png',
        [AVAILABLE_LANGUAGES.ar]: websiteLogoArWhite.src,
    };
    const [logoSrc, setLogoSrc] = useState<string>(
        logoByLanguage[DEFAULT_LANGUAGE]
    );
    const [currentActiveLink, setCurrentActiveLink] = useState<string>('');
    const { isAndroid, isHuawei, isIos } = useUserAgent();
    useEffect(() => {
        const footerFlag = shouldHideFooter(desktopView, path);
        if (hideFooter !== footerFlag) {
            setHideFooter(footerFlag);
        }

        const arrayPath = path.split('/');
        const pathLength = arrayPath.length;
        const current = pathLength === 2 ? '' : arrayPath[pathLength - 1];
        setCurrentActiveLink(current);
    }, [path, desktopView]);

    useEffect(() => {
        setLogoSrc(
            logoByLanguage[
                AVAILABLE_LANGUAGES[i18n.language as AVAILABLE_LANGUAGES]
            ]
        );
    }, [i18n.language]);
    const carListingFlagInPartialLoading = useContext(globalConfigContext);
    const openingAppHandler = ({ storeLink }: { storeLink: string }) => {
        const carID = selectedCar?.id || ''; // This will be used in case of extracting id from thank-you route (booking car) to go to financing screen
        const pathname = window.location.pathname.replace(/^\/(en|ar)\//, '');
        const pathSegments = pathname.split('/');
        let matchedScreen = null;
        for (const screen of appScreensMap) {
            const routeParts = screen.websiteRoute.split('/');
            if (pathSegments.length >= routeParts.length) {
                let id = null;
                let isMatch = true;
                for (let i = 0; i < routeParts.length; i++) {
                    if (routeParts[i].startsWith(':')) {
                        id = pathSegments[i];
                    } else if (routeParts[i] !== pathSegments[i]) {
                        isMatch = false;
                        break;
                    }
                }
                if (isMatch) {
                    if (screen.websiteRoute === 'thank-you') {
                        matchedScreen = screen.targetScreen(carID); // Use carID from Redux
                    } else if (
                        screen.websiteRoute === 'mybookings/:bookingId/payments'
                    ) {
                        matchedScreen = screen.targetScreen(id); // Use bookingId from dynamic path
                    } else if (
                        screen.websiteRoute ===
                        'account/mybookings/:bookingId/payments'
                    ) {
                        matchedScreen = screen.targetScreen(id); // Use bookingId from dynamic path
                    } else {
                        matchedScreen = screen.targetScreen(id); // Use dynamic ID for other routes
                    }
                    break;
                }
            }
        }
        if (matchedScreen) {
            trackEvent('Click On Download App', {
                userId,
                sourceButton: 'Footer',
                phone,
                email,
            });
            openApp({
                storeLink,
                targetScreen: matchedScreen,
            });
        } else {
            openApp({
                storeLink,
                targetScreen: 'HomeScreen',
            });
        }
    };
    return (
        <div
            className={`bg-cover flex flex-col px-3.5 py-10 lg:px-20 lg:py-16 ${
                hideFooter || carListingFlagInPartialLoading.hideFooter
                    ? ' hidden'
                    : ''
            }`}
            style={{ backgroundImage: `url(${FooterBackground.src})` }}
        >
            <div className={styles.linksButtonsContainer}>
                <div className={styles.headerSection}>
                    <div className={styles.headersContainer}>
                        <Typography
                            className={styles.header}
                            variant={'h5SemiBold'}
                        >
                            {t('Start With Sylndr')}
                        </Typography>
                    </div>
                    <div className={styles.buttonsContainer}>
                        <Link
                            className={styles.sellButton}
                            onClick={() =>
                                currentActiveLink === 'sell-car' &&
                                window.scrollTo({
                                    top: 0,
                                    left: 0,
                                    behavior: 'smooth',
                                })
                            }
                            href={`/${lang}/sell-car`}
                        >
                            <Typography
                                variant="body1Medium"
                                className={styles.buttonText}
                            >
                                {' '}
                                {t('Sell Car')}
                            </Typography>
                        </Link>
                        <Link
                            className={styles.buyButton}
                            href={`/${lang}/buy-used-cars`}
                            onClick={() => {
                                currentActiveLink === 'buy-used-cars' &&
                                    window.scrollTo({
                                        top: 0,
                                        left: 0,
                                        behavior: 'smooth',
                                    });
                                trackEvent(ACTION_CLICK_BUY_CAR, {
                                    source: possibleBuyUseCarsSources.footer,
                                });
                            }}
                            //   state={{ sourcePage: 'Buy Footer' }}
                        >
                            <Typography
                                variant="body1Medium"
                                className={styles.buttonText}
                            >
                                {' '}
                                {t('Buy')}
                            </Typography>
                        </Link>
                        <a className={styles.hotlineTag} href="tel:17188">
                            <div className={styles.ContactUsButton}>
                                <Image
                                    src={PhoneIcon.src}
                                    width={PhoneIcon.width}
                                    height={PhoneIcon.height}
                                    loading={'lazy'}
                                    alt="mobile number"
                                />
                                <Typography
                                    variant="body1Medium"
                                    className={styles.buttonText}
                                >
                                    17188
                                </Typography>
                            </div>
                        </a>
                    </div>
                </div>
                <div className={styles.linksSection}>
                    <div className={styles.companyBriefContainer}>
                        {/* eslint-disable-next-line @next/next/no-img-element */}
                        <img
                            fetchPriority={'low'}
                            src={myImageLoader({
                                src: logoSrc,
                                width: 142,
                                quality: 80,
                                fillColor: 'transparent',
                            })}
                            alt="Sylndr's Logo"
                            className={
                                lang === 'ar' ? styles.logoAr : styles.logo
                            }
                        />
                        <Typography className={styles.brief} variant={'body2'}>
                            {t('Sylndr Brief')}
                        </Typography>
                    </div>
                    <div className="mt-8 mb-4 flex flex-col gap-3 md:hidden">
                        <Typography
                            variant="body2SemiBold"
                            className="text-gray-400"
                        >
                            {t('getSylndrAppProfile')}
                        </Typography>
                        <div className="flex items-center justify-between gap-3">
                            {isIos && (
                                <Image
                                    onClick={() => {
                                        openingAppHandler({
                                            storeLink: APP_STORE_LINK,
                                        });
                                    }}
                                    src={GetOnAppleStore}
                                    alt="apple-store"
                                    className="w-full h-full max-w-[125px]"
                                />
                            )}
                            {isAndroid && (
                                <Image
                                    onClick={() => {
                                        openingAppHandler({
                                            storeLink: GOOGLE_PLAY_LINK,
                                        });
                                    }}
                                    className="w-full h-full max-w-[125px]"
                                    src={GetOnAndroidStore}
                                    alt="android-store"
                                />
                            )}
                            {isHuawei && (
                                <Image
                                    onClick={() => {
                                        openingAppHandler({
                                            storeLink: HUAWEI_PLAY_LINK,
                                        });
                                    }}
                                    className="w-full h-full max-w-[125px]"
                                    src={GetOnHuaweiStore}
                                    alt="huawei-store"
                                />
                            )}
                        </div>
                    </div>
                    <div className={styles.linksContainer}>
                        {NAVIGATION_LINKS.map((navigationGroup, index) => {
                            return (
                                <div key={index} className={styles.navGroup}>
                                    <Typography
                                        className={styles.groupHeader}
                                        variant={'body2SemiBold'}
                                    >
                                        {t(
                                            navigationGroup.category as unknown as TemplateStringsArray
                                        )}
                                    </Typography>
                                    {navigationGroup.links.map(link => {
                                        if (link.label === 'Finance')
                                            return (
                                                <Link
                                                    key={link.path}
                                                    href={`/${lang}/car-finance`}
                                                    //   state={{ sourcePage: 'Finance Footer' }}
                                                    className={styles.navItem}
                                                    onClick={() => {
                                                        trackEvent(
                                                            'Screen: Financing Page',
                                                            {
                                                                sourceScreen:
                                                                    'Footer',
                                                            }
                                                        );
                                                        currentActiveLink ===
                                                            link.path &&
                                                            window.scrollTo({
                                                                top: 0,
                                                                left: 0,
                                                                behavior:
                                                                    'smooth',
                                                            });
                                                    }}
                                                >
                                                    <Typography variant="body1Medium">
                                                        {' '}
                                                        {t(
                                                            link.label as unknown as TemplateStringsArray
                                                        )}
                                                    </Typography>

                                                    <Typography
                                                        className={
                                                            styles.newBadge
                                                        }
                                                        variant={
                                                            'captionMedium'
                                                        }
                                                    >
                                                        {t('New')}
                                                    </Typography>
                                                </Link>
                                            );
                                        return (
                                            <Link
                                                key={link.path}
                                                href={
                                                    navigationGroup.category ===
                                                        'Social' ||
                                                    link.label === 'Careers' ||
                                                    link.label === 'Blog'
                                                        ? link.path
                                                        : `/${lang}/` +
                                                          link.path
                                                }
                                                className={styles.navItem}
                                                target={
                                                    navigationGroup.category ===
                                                        'Social' ||
                                                    link.label === 'Careers'
                                                        ? '_blank'
                                                        : '_self'
                                                }
                                                rel="noopener noreferrer"
                                            >
                                                <Typography variant="body1Medium">
                                                    {' '}
                                                    {t(
                                                        link.label as unknown as TemplateStringsArray
                                                    )}
                                                </Typography>
                                            </Link>
                                        );
                                    })}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <div className={styles.bottomSection}>
                <Typography className={styles.copyRights} variant={'body1'}>
                    {t('Sylndr Rights')}
                </Typography>
                <div className={styles.socialMediaLinks}>
                    {SOCIAL_MEDIA_LINKS.map(item => {
                        return (
                            <a
                                href={item.url}
                                key={item.id}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {/* eslint-disable-next-line @next/next/no-img-element */}
                                <img
                                    fetchPriority={'low'}
                                    src={item.logo}
                                    className={styles.socialMediaLogo}
                                    alt="Sylndr's Social Media"
                                />
                            </a>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
