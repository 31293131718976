'use client';
import { APP_STORE_LINK, GOOGLE_PLAY_LINK } from '@/constants';
import { useEffect, useState } from 'react';

const useStoreLink = () => {
    const [storeLink, setStoreLink] = useState<string | null>(null);

    useEffect(() => {
        const agent = window.navigator.userAgent;

        // Detect device type
        const isIPhone = /iPhone/i.test(agent);
        const isAndroid = /Android/i.test(agent);

        if (isIPhone) {
            setStoreLink(APP_STORE_LINK);
        } else if (isAndroid) {
            setStoreLink(GOOGLE_PLAY_LINK);
        }
    }, [APP_STORE_LINK, GOOGLE_PLAY_LINK]);

    return storeLink;
};

export default useStoreLink;
