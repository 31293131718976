import { useEffect, useState } from 'react';

interface UserAgent {
    isIos: boolean;
    isAndroid: boolean;
    isHuawei: boolean;
}

const useUserAgent = (): UserAgent => {
    const [userAgent, setUserAgent] = useState<UserAgent>({
        isIos: false,
        isAndroid: false,
        isHuawei: false,
    });

    useEffect(() => {
        if (typeof navigator !== 'undefined') {
            const userAgentString = navigator.userAgent.toLowerCase();
            const isIos = /iphone|ipad|ipod/.test(userAgentString);
            const isAndroid = /android/.test(userAgentString);
            const isHuawei = /huawei|honor/.test(userAgentString);

            setUserAgent({
                isIos,
                isAndroid: isAndroid && !isHuawei, // Ensure Huawei is separate from Android
                isHuawei,
            });
        }
    }, []);

    return userAgent;
};

export default useUserAgent;
